.cp-account {
  h2 {
    font-size: 1.5rem;
  }

  .download-actions {
    display: flex;

    button {
      margin-right: 15px;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;

      > * {
        margin-top: 15px;
        max-width: 150px;
      }
    }
  
  }
}