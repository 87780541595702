.cp-password-field {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-field {
    margin-right: 1rem;
    flex-grow: 1;
  }

  button, .cp-modal {
    flex-shrink: 0;
  }
}
