@import '../variables.scss';

.cp-password-requirements {
  .password-requirements {
    margin-top: 0;
  }

  .invalid {
    color: $error-color;
  }
}
