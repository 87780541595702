@import './variables';
@import '~materialize-css/sass/materialize';

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.cp-app {
  color: $foreground-color;
  background-color: $background-color;
}

.cp-app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;

  > div:not(.cp-home) {
    max-width: 800px;
    margin: auto;
  }
}

.btn-negative {
  background-color: $negative-color;

  &:hover,
  &:focus {
    background-color: lighten($negative-color, 5%)
  }
}

.btn-secondary {
  background-color: $neutral-color;

  &:hover,
  &:focus {
    background-color: lighten($neutral-color, 5%)
  }
}

strong {
  font-weight: 600;
}

hr {
  margin: 2rem -5%;
  border-top: 0;
  border-bottom: 0;
}

.brand-logo, h1, h2, h3, h4, h5 {
  font-family: 'Lato', sans-serif;
}

a {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

h2 {
  font-size: 1.4rem;
}



.cp-app {
  transition: color $dark-mode-transition-time;
  transition: background-color $dark-mode-transition-time;

  nav, footer {
    transition: background-color $dark-mode-transition-time;
  }

  .collection .collection-item {
    transition: background-color $dark-mode-transition-time;
  }

  .btn-flat {
    transition: color $dark-mode-transition-time;
  }

  input {
    transition: color $dark-mode-transition-time;
  }
  
  .btn-small[disabled] {
    transition: background $dark-mode-transition-time;
  }
}


.cp-app.dark-mode {
  background-color: $dark-mode-background;
  color: $dark-mode-foreground;

  nav, footer {
    background-color: $dark-mode-nav;
  }

  .collection .collection-item {
    background-color: $dark-mode-background;
    border-color: lighten($dark-mode-background-light, 5%);
  }

  .modal, .modal .modal-footer {
    background-color: $dark-mode-background-light;
  }

  .btn-flat {
    color: $dark-mode-foreground;
  }

  input,
  textarea {
    color: $dark-mode-foreground !important;
  }

  input:focus + label,
  textarea:focus + label {
    color: $dark-mode-selected !important;
    border-color: $dark-mode-selected !important;
  }

  input[type=range] {
    border-color: $dark-mode-background-light;
  }
  
  .btn-small[disabled] {
    background: $dark-mode-background-light !important;
  }

  .dropdown-content {
    background: $dark-mode-background-light;

    li > span {
      color: $dark-mode-foreground;
    }
  }

  p > a, .title > a {
    color: $dark-mode-selected;
  }

  p > a:visited {
    color: $dark-mode-visited;
  }

  .btn {
    background-color: $dark-mode-primary;
  }

  .btn-secondary {
    background-color: $dark-mode-secondary;
  }

  .btn-negative {
    background-color: $dark-mode-negative;
  }
}