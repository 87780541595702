// Colours
$bird-dark-blue: #1A2668;
$bird-blue: #164ABC;
$bird-dark-orange: #DA5A14;
$bird-orange: #EB9417;
$bird-green: #3F9331;
$bird-yellow: #F6D131;
$bird-white: #FFF;
$bird-black: #000;

$background-color: $bird-white;
$foreground-color: $bird-black;

// Uses
$header-color: $bird-green;
$button-color: $bird-blue;
$link-color: $bird-blue;

$negative-color: $bird-dark-orange;
$neutral-color: $bird-orange;

// Materialize Variable Overrides
$primary-color: $bird-green;
$primary-color-light: lighten($primary-color, 10%);
$primary-color-dark: darken($primary-color, 10%);

$secondary-color: $bird-blue;

$positive-color: $bird-green;
$error-color: $bird-dark-orange;

/*
$input-border-color: #424776;
$input-focus-color: $bird-white;
$collection-bg-color: $background-color;
$collection-border-color: #424776;
$off-black: $foreground-color;
*/

$dark-mode-nav: darken($header-color, 20%);
//$dark-mode-nav: darken($bird-blue, 20%);

$dark-mode-foreground: darken(#fff, 10%);
$dark-mode-background: #1f1f1f;
$dark-mode-background-light: #2A2A2A;
$dark-mode-selected: lighten($bird-blue, 20%);
$dark-mode-visited: lighten($bird-blue, 40%);
$dark-mode-transition-time: .5s;
$dark-mode-secondary: darken($bird-orange, 20%);
$dark-mode-negative: darken($bird-dark-orange, 20%);
$dark-mode-primary: darken($bird-blue, 10%);