@import '../../variables.scss';

.cp-copyable-field {
  display: flex;

  .copy-link {
    margin-left: 1em;
  }
}

.toast.copy-success {
  background-color: $dark-mode-background-light;

  i {
    color: $positive-color;
    margin-right: .5rem;
  }
}
