.cp-home {
  text-align: center;

  .bird-banner {
    margin-top: 2em;
    width: 100%;

    svg {
      display: block;
      margin: auto;
      max-width: 500px;
      width: 100%;
    };
  }

  .heading {
    margin-bottom: 3rem;

    h1 {
      margin: 0;
    }
  }

  .subtitle {
    margin-top: 0;
  }

  .promo-example {
    max-width: 250px;
    margin: auto;
    margin-bottom: 3em;
  }

  .sign-up-link {
    margin-bottom: 3em;
  }
}