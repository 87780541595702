.cp-item {
  display: grid;
  justify-content: space-between;
  grid-template-columns: calc(100% - 395px) 150px 150px 50px;
  grid-gap: 0 15px;

  .title {
    font-size: 16px;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  button {
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    display: block;

    > * {
      display: block;
      width: 100%;
      margin-bottom: 1em;
    }

    button {
      width: 100%;
    }
  }
}