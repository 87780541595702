.cp-details {
  .title {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .actions {
    margin: 1em 0;
    display: flex;
    justify-content: space-between;

    > :not(.delete-button) {
      width: 40%;
    }
  }
}