.cp-empty-state {
  text-align: center;

  .graphic {
    max-width: 150px;
    width: 100%;
  }

  .title {
    font-size: 1.4rem;
    margin-bottom: 0
  }

}
